@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


@font-face {
    font-family: Poppins;
    src: url(../public/fonts/Poppins-Medium.ttf)
}

@font-face {
    font-family: Poppins;
    src: url(../public/fonts/Poppins-Regular.ttf)
}

.active{
    /* color: #ff354c; */
    border-bottom: 2px solid #ff354c;
    width: fit-content;
    scale: 1.08;
}

.firstServiceImage {
    -webkit-mask-image: linear-gradient(to right, transparent 0.5%, #0e0e0e8e 50%);
    mask-image: linear-gradient(to right, transparent 0.5%, #0e0e0e8e 50%);
  }

  .bg-abstract{
    background-image: url(./components/img/download.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }